.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
.login-wrapper {
  box-sizing: border-box;
  min-height: 100vh;
  padding-top: 90px;
  background: url(https://mktv-in-cdn.mockuai.com/16276566795813067.png) top left / cover no-repeat, linear-gradient(270deg, #001554 0%, #00092A 100%);
}
.login-wrapper main {
  padding: 14.16666667vw 0;
}
.login-wrapper main .content-box {
  width: 62.5vw;
  margin: 0 auto;
}
.login-wrapper main .content-box h3.title {
  text-align: center;
  margin-bottom: 0.8854166666666666vw;
  line-height: 5.833333333333333vw;
  font-size: 4.166666666666666vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
}
.login-wrapper main .content-box .desc {
  margin-bottom: 2.604166666666667vw;
  line-height: 1.7187500000000002vw;
  font-size: 1.25vw;
  text-align: center;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
}
.login-wrapper main .content-box .login-platforms {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-wrapper main .content-box .login-platforms .platform-item {
  position: relative;
  margin-right: 3.3333333333333335vw;
  width: 9.375vw;
  height: 5.416666666666667vw;
}
.login-wrapper main .content-box .login-platforms .platform-item:last-child {
  margin-right: 0;
}
.login-wrapper main .content-box .login-platforms .platform-item .box,
.login-wrapper main .content-box .login-platforms .platform-item .hover-box {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.login-wrapper main .content-box .login-platforms .platform-item .box {
  visibility: visible;
}
.login-wrapper main .content-box .login-platforms .platform-item .hover-box {
  visibility: hidden;
}
.login-wrapper main .content-box .login-platforms .platform-item .hover-box .btn:nth-child(1) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.login-wrapper main .content-box .login-platforms .platform-item .hover-box .btn:nth-child(2) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.login-wrapper main .content-box .login-platforms .platform-item:hover .box {
  visibility: hidden;
}
.login-wrapper main .content-box .login-platforms .platform-item:hover .hover-box {
  visibility: visible;
}
.login-wrapper main .content-box .login-platforms .platform-item.scrm:hover .box {
  visibility: visible;
}
.login-wrapper main .content-box .login-platforms .btn {
  text-decoration: none;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9.375vw;
  height: 2.7083333333333335vw;
  border-radius: 0.20833333333333334vw;
  background-color: #1989fa;
  font-size: 0.9375vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
}
.login-wrapper main .content-box .login-platforms .btn .icon {
  width: 2.5vw;
  height: 2.5vw;
  margin-right: 0.5729166666666666vw;
}
.login-wrapper main .content-box .login-platforms .btn .text {
  font-size: 0.9375vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
}
@media screen and (min-width: 1920px) {
  .login-wrapper main .content-box {
    width: 1200px;
  }
  .login-wrapper main .content-box h3.title {
    line-height: 112px;
    font-size: 80px;
    margin-bottom: 17px;
  }
  .login-wrapper main .content-box .desc {
    line-height: 33px;
    font-size: 24px;
    margin-bottom: 50px;
  }
  .login-wrapper main .content-box .login-platforms .platform-item {
    width: 180px;
    height: 104px;
    margin-right: 64px;
  }
  .login-wrapper main .content-box .login-platforms .btn {
    width: 180px;
    height: 52px;
    font-size: 18px;
  }
  .login-wrapper main .content-box .login-platforms .btn .icon {
    width: 48px;
    height: 48px;
  }
  .login-wrapper main .content-box .login-platforms .btn .text {
    font-size: 18px;
  }
}
@media screen and (max-width: 1066px) {
  .login-wrapper main {
    padding: 151.017px 0;
  }
  .login-wrapper main .content-box {
    width: 666.25px;
  }
  .login-wrapper main .content-box h3.title {
    font-size: 44.4167px;
    line-height: 62.1833px;
    margin-bottom: 9.43854px;
  }
  .login-wrapper main .content-box .desc {
    font-size: 13.325px;
    line-height: 18.3219px;
    margin-bottom: 27.7604px;
  }
  .login-wrapper main .content-box .login-platforms .platform-item {
    margin-right: 35.5333px;
    width: 99.9375px;
    height: 57.7344px;
  }
  .login-wrapper main .content-box .login-platforms .btn {
    width: 99.9375px;
    height: 28.8594px;
    font-size: 10px;
  }
  .login-wrapper main .content-box .login-platforms .btn .icon {
    width: 26.6406px;
    height: 26.6406px;
  }
  .login-wrapper main .content-box .login-platforms .btn .text {
    font-size: 10px;
  }
}
